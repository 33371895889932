import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import StudentReviews from '../components/StudentReviews';
import FullWidthImage from '../components/FullWidthImage';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import TitleImg from '../components/TitleImg';

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  overlay,
  title,
  heading,
  intro,
  mainpitch
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage
        className={'cover-img'}
        img={heroImage}
        title={title}
        heading={heading}
        overlay={overlay}
      />
      <section className="section mainpitch-section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-7">
                <div className="intro has-text-centered">
                  <h2 className="title hero-title">{intro.title}</h2>
                  <p>{intro.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section courses-section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="mainpitch">
                  <TitleImg
                    title={mainpitch.title}
                    titleClass={'courses-title'}
                    imgSrc={'/img/dog-graduation.png'}
                    imgClass={'courses-title-img'}
                  />
                  <Link className="maintpitch-img-link" to="/cursos-e-horarios">
                    <PreviewCompatibleImage
                      className="mainpitch-img"
                      imageInfo={mainpitch.image}
                    />
                  </Link>
                  <Link
                    className="button btn-readmore mainpitch-btn"
                    to="/cursos-e-horarios"
                  >
                    Saber Mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section students-section section--gradient">
        <div className="container">
          <div className="content"></div>
          <div className="columns">
            <div className="column is-10 is-offset-1 has-text-centered">
              <TitleImg
                title={'Nossos Alunos'}
                titleClass={'students-title'}
                imgSrc={'/img/alunos.png'}
                imgClass={'students-title-img'}
              />
              <StudentReviews />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  overlay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  intro: PropTypes.object,
  mainpitch: PropTypes.object
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        overlay={frontmatter.overlay}
        title={frontmatter.title}
        heading={frontmatter.heading}
        intro={frontmatter.intro}
        mainpitch={frontmatter.mainpitch}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        overlay {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 64, layout: CONSTRAINED)
          }
        }
        title
        heading
        intro {
          title
          description
        }
        mainpitch {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(height: 420, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
