import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { htmlParser } from '../helpers/htmlParser';

const StudentReviewsTemplate = (props) => {
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <div className="student-reviews columns is-centered">
      {posts &&
        posts.map(({ node: post }) => (
          <div className="column is-4" key={post.id}>
            <article className="student-card is-flex is-flex-direction-column is-justify-content-between">
              {post?.frontmatter?.image && (
                <PreviewCompatibleImage
                  className="student-image"
                  imageInfo={{
                    image: post.frontmatter.image,
                    alt: `${post.frontmatter.heading}`,
                    width:
                      post.frontmatter.image.childImageSharp.gatsbyImageData
                        .width,
                    height:
                      post.frontmatter.image.childImageSharp.gatsbyImageData
                        .height
                  }}
                />
              )}
              <div className="student-card-text">
                <div className="student-card-description">
                  {htmlParser(post.frontmatter.description)}
                </div>
                <h4 className="student-card-heading">
                  - {post.frontmatter.heading}
                </h4>
              </div>
            </article>
          </div>
        ))}
    </div>
  );
};

StudentReviews.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default function StudentReviews() {
  return (
    <StaticQuery
      query={graphql`
        query StudentReviewsQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "student" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  heading
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  description
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        width: 240
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <StudentReviewsTemplate data={data} count={count} />
      )}
    />
  );
}
