import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PreviewCompatibleImage from './PreviewCompatibleImage';

export default function FullWidthImage(props) {
  const {
    height = 460,
    className,
    img,
    title,
    heading,
    overlay,
    imgPosition = 'left center'
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
          height: height
        }}
      >
        {img?.url ? (
          <img
            src={img}
            className={className}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              flex: 1,
              width: '100%'
            }}
            alt=""
          />
        ) : (
          <GatsbyImage
            image={img}
            className={className}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              flex: 1,
              maxHeight: height
            }}
            layout="fullWidth"
            aspectratio={3 / 1}
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        )}

        {(title || heading || overlay) && (
          <div className="hero-content has-text-centered">
            {overlay && (
              <PreviewCompatibleImage
                className={'overlay-img'}
                imageInfo={{
                  image: overlay,
                  alt: 'Doggy Clube'
                }}
              />
            )}
            {title && (
              <h1 className="hero-title has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
                {title}
              </h1>
            )}
            {heading && (
              <div className="columns is-centered is-vcentered">
                <div className="column is-full hero-heading-column has-text-centered">
                  <div className="hero-heading-container">
                    <h2 className="hero-heading has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                      {heading}
                    </h2>
                  </div>
                </div>
              </div>
            )}
            <Link className="hero-btn button btn-readmore " to="/cursos-e-horarios">
              Saber Mais
            </Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  overlay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string
};
